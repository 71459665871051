.font {
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: flex-start;
}

.logo {
    position: fixed;
    top: 23px;
    margin-left: 38px;
    height: 35px;
    opacity: 1;
}

.jeNavigue {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 1600px;
    height: 80px;
    background: #FFFFFF;
}

.accueil /*accueil*/{
    top: 23px;
    margin: auto;
    font-size: 35px;
    font-weight: bold;
    color: #00004d;
    opacity: 1;
}

.projets /*projets*/{
    top: 23px;
    margin: auto;
    font-size: 35px;
    font-weight: bold;
    color: #00004d;
    opacity: 1;
}

.propos {
    top: 23px;
    margin: auto;
    font-size: 35px;
    font-weight: bold;
    color: #00004d;
    opacity: 1;
}

.contact {
    top: 23px;
    margin: auto;
    font-size: 35px;
    font-weight: bold;
    color: #00004d;
    opacity: 1;
}

.burger {
    /* top: 20px; */
    height: 28px;
    /* background: url() 0% 0% no-repeat padding-box; */
    /* opacity: 1; */
}

.tools {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: space-between;
    position: absolute;
    right: 0px;
    top: 12px;
}

a {
    text-decoration: none;
}

.nageur {
    background-size: cover;
    position: absolute;
    top: 80px;
    left: 0;
}

.nageurScoped {
    background-size: cover;
    position: absolute;
    top: -160px;
    left: 0;
}

@media (max-width: 768px)  {
  .navbar-brand {
    height: 30px;
  }
}
