.banner {
  background-size: cover;
  background-position: center;
  padding: 320px 0;
}

.banner .h1 {
  margin: 0;
  color: white;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  font-size: 32px;
  font-weight: bold;
}

.banner .h1:hover {
  color: lightgrey;
}

.banner2 {
  background-size: unset;
  background-position: 25%;
  padding: 240px 0;
}
