@import url(./stylesheets/sponsor.css);
@import url(./stylesheets/header.css);
@import url(./stylesheets/inputs.css);
@import url(./stylesheets/banner.css);
@import url(./stylesheets/footer.css);
@import url(./stylesheets/card.css);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.column-sponsor {
  background: #fff;
  max-width: 50%;
  width: 50%;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: scroll;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  }
  &:last-child {
    background: black;
    border-top: 1px solid rgba(255,255,255,0.5);
  }
  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }

  .btn-ghost {
    color: white;
    border: 1px solid white;
    padding: 8px 24px;
    border-radius: 50px;

    opacity: 1;
    transition: opacity 0.3s ease;
    background: transparent;
  }

  .btn-ghost:hover {
    opacity: 1;
    background: white;
    color: black;
  }

  .btn-ghost2 {
    color: black;
    border: 1px solid black;
    padding: 8px 24px;
    border-radius: 50px;

    opacity: 1;
    transition: opacity 0.3s ease;
    background: transparent;
  }

  .btn-ghost2:hover {
    opacity: 1;
    background: white;
    color: black;
  }

.btn-primary  {

  border: 1px solid #ff5757 !important;
  background: #ff5757 !important;
  font-family: 'Poppins', sans-serif;
}
